import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { defineCustomElements as gnxCatalogueWcomponent } from "gnx-catalogue-wcomponent/loader";
import { defineCustomElements as gnxOrdersWcomponent } from "gnx-orders-wcomponent/loader";
import { defineCustomElements as gnxOnboardingWcomponent } from "gnx-onboarding-wcomponent/loader";
import { defineCustomElements as GnxSocialNetworkWcomponent } from "gnx-social-network-wcomponent/loader";
import { defineCustomElements as GnxChallengesWcomponent } from "gnx-challenges-wcomponent/loader";

import { CredentialsService } from "./app/services/credentials/credentials.service";

if (environment.production) {
  enableProdMode();
}



(async function () {
  const originalFetch = window.fetch;
  const platform = platformBrowserDynamic();
  const moduleRef = await platform.bootstrapModule(AppModule);
  // Obtener el inyector
  const injector = moduleRef.injector;
  const myService = injector.get(CredentialsService);

  const elastic = environment.gluky.catalogueApiBasePath;
  const engine = myService.catalogue().engine_name;

  window.fetch = async function (url, options = {}) {

      if (url.toString().includes(`${elastic}/api/as/v1/engines/${engine}/search`)) {
      // Obtener el token 
      const tokenData = JSON.parse(localStorage.getItem("_cap_elasticToken"));
      let currentTime = Math.floor(Date.now() / 1000);
      let timeReloadToken = (tokenData.token_expiry - currentTime);
      console.log("***time elastic", timeReloadToken);
      
      if (timeReloadToken <= 0) {
        myService.catalogueNew();
      }
      // Verificar que el token existe
      if (tokenData && tokenData.token) {
        const token = tokenData.token;

        // Añadir el header de autorización con el token dinámico
        options.headers = options.headers || {};
        const headers = new Headers(options.headers);
        headers.set('Authorization', `Bearer ${token}`);
        options.headers = headers;
      } else {
        console.warn('Token no encontrado o inválido');
      }
    }

    try {
      const response = await originalFetch(url, options);
      // Registrar la respuesta
      console.log('Intercepted Response:', response);
      // Manejar errores HTTP
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response;
    } catch (error) {
      // Manejar errores
      console.error('Fetch error:', error);
      throw error;
    }
  };
})();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));

gnxCatalogueWcomponent(window);
gnxOrdersWcomponent(window);

gnxOnboardingWcomponent(window);

GnxSocialNetworkWcomponent(window);
GnxChallengesWcomponent(window);
