import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import { LanguagesService } from '../languagesService/languages.service';

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  lang: string;
  constructor(public loadingController: LoadingController, private languageService: LanguagesService) {
    this.lang = this.languageService.language;
  }


  async presentLoading(id) {
    console.log("___loader con id= ",id);
    const loading = await this.loadingController.create({
      cssClass: "my-custom-class",
      message: this.languageService.textsIU.loader_message.message,
      id,
    });
    await loading.present();
  }

  async cancelLoader(id) {
    console.log("___loader cancelLoader=",id);
    if (this.loadingController) {
      setTimeout(async () => {
       const loader = await document.getElementById(id);
       if (loader) {
        loader.remove();
       }
        await this.loadingController.dismiss(id);
      }, 500);
    }
  }
}
