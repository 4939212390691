// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appName: "GNX Reckit Don Rebueno",
  gmApiKey: "AIzaSyDIsRLNo6SbW448TbxBOS5cdY299sGLwuc",
  firebaseConfig: {
    apiKey: "AIzaSyDIsRLNo6SbW448TbxBOS5cdY299sGLwuc",
    authDomain: "gnx-don-rebueno-reckit-prd.firebaseapp.com",
    projectId: "gnx-don-rebueno-reckit-prd",
    storageBucket: "gnx-don-rebueno-reckit-prd.appspot.com",
    messagingSenderId: "698403667861",
    appId: "1:698403667861:web:abd2467531d5e2e16e409c"
  },
  gluky: {
    app_id: "gnx_don_rebueno_reckit_prd",
    programId: "don_rebueno_asociados",
    companyId: "rb_health_colombia_s_a_s",
    apikey: "AIzaSyDIsRLNo6SbW448TbxBOS5cdY299sGLwuc",
    apikeyPwa: "AIzaSyDIsRLNo6SbW448TbxBOS5cdY299sGLwuc",
    apiKeyWeb: "AIzaSyDIsRLNo6SbW448TbxBOS5cdY299sGLwuc",
    apiKeyAndroid: "AIzaSyDIsRLNo6SbW448TbxBOS5cdY299sGLwuc",
    usersApiBasePath: 'https://users-api.glukynet.com/v2',
    ordersApiBasePatch: 'https://orders-api.glukynet.com/v1',
    pointsApiBasePath: 'https://bank-api.glukynet.com/v1',
    bffApiBasePath:"https://bffv2-cms.glukynet.com/v2",
    productsApiBasePath: 'https://products-front-api.glukynet.com/v1',
    catalogueApiBasePath:'https://fb07883f71224ce39a631c312f9e280b.ent-search.us-east1.gcp.elastic-cloud.com',
    challengesStrapiApiBasePath:"https://bff-api.cms.glukynet.com/v1/contents/challenges",
    cmsBffApiBasePath:"https://cms-bff-v3-2kntzite.ue.gateway.dev/v3",
    productsUrl: "https://products-front-api.glukynet.com/v1",
    apinutresa: "https://us-central1-nutresa-partners-api.cloudfunctions.net/v2",
    recognitionsUrl: 'https://recognitions-front-us-east1-bryp80ke.ue.gateway.dev/v1',
    kpiFrontApiBasePath: "https://kpis-front-us-east1-21cvo6vg.ue.gateway.dev/v1",
    customProductsUrl: "https://custom-products-production-us-east1-37rslm8i.ue.gateway.dev/api/v1",
    pointsNewApiBasePath: "https://pointz-main-test-us-east1-5202s9wg.ue.gateway.dev/main/v1",
    bffApiBasePathV2: "https://bffv2-cms.glukynet.com/v2",
    algoliaSearchUrl: 'https://instantsearch-us-east1-czsrv8lj.ue.gateway.dev/v1/front/simple/app/',
    algoliaSearch: 'https://instantsearch-us-east1-czsrv8lj.ue.gateway.dev/v1/front/simple/app/',
    cloudinaryUrl: 'https://res.cloudinary.com/glukygroup/',
    exhibitionData:'https://exhibitions-front-us-east1-cux1ukor.ue.gateway.dev/v1/frontend'
    
  },
  recaptcha: {
    siteKey: '6LdPLxoqAAAAAJS_7hC_YnnKvajcvVQNatloomM3',
  },
  country: "co",
  img_profile_default:
    "https://firebasestorage.googleapis.com/v0/b/gnx-mvp.appspot.com/o/defaults%2Fdefault-user.jpg?alt=media&token=15fbfa41-6ba2-4a88-8530-e5e5f41e45a4",
  phoneIndicator: "+57",
  clevertapAccountId: "4R6-87K-895Z",
  pathNotification: "https://notifications-api.glukynet.com/v1",
  dinamycLink: {
    url: "https://mvpimproved.page.link/C1mr",
    packageName: "com.gluky.mvpimproved",
  },
  geochallenges: {
    proximityRadiusInMeters: 800
  },
  saml: {
    provider: 'saml.bancolombia',
    url: 'https://gnx-bancolombia-reconocerte.firebaseapp.com/__/auth/handler'
  },
  emblu: {
    endpoint: "https://us-central1-gnx-bavaria-blovers-prd.cloudfunctions.net/getEmblueToken",
    associatePushToEmail: "https://us-central1-gnx-bavaria-blovers-prd.cloudfunctions.net/setAssociatePushToEmail",
    newContact: "https://us-central1-gnx-bavaria-blovers-prd.cloudfunctions.net/setNewContact"
  },
  frontendPuntoRedApi:'https://puntored-frontend-stg-us-east1-1g6o62sh.ue.gateway.dev/v1',
  public_id_cloudinary: '/Brands/Brands-test/',
  cloudinary_config: {
    cloud_name: 'glukygroup',
    apiKey: '112786663666772',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
