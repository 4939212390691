import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from '../storage/storage.service';
import { AlertService } from '../alert/alert.service';
import { EventsService } from '../events/events.service';
import { environment } from 'src/environments/environment';
import { finalize, map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { Filesystem, FilesystemDirectory } from '@capacitor/core';
import firebase from 'firebase';
import { Observable } from 'rxjs';


export type Filters = 'accepted' | 'in-progress' | 'rejected';
export type ExhibitsTypes = 'invoice' | 'exhibition';
export type Status = 'idle' | 'pending' | 'resolved' | 'rejected';
export type ItemData = { [field: string]: any } & { status: Filters; uid: firebase.User['uid'] };
export type ListData = { id: string, data: ItemData }[];

@Injectable({
  providedIn: 'root'
})
export class ExhibitionsService {
  apiKey = environment.gluky.apikey;
  appId = environment.gluky.app_id;
  type: ExhibitsTypes = 'exhibition';
  error = false;

  constructor(
    private firestore: AngularFirestore,
    private storageServices: StorageService,
    private alertService: AlertService,
    private storage: AngularFireStorage,
    private events: EventsService,
    private http: HttpClient
  ) {}

  newId(): string {
    return firebase.firestore().collection('temp').doc().id;
  }

  async saveExhibition(data: any): Promise<string | number> {
    if (data.photos.length > 0) {
        data.createAt = new Date();
        data.timestamp = new Date();
        data.statusHistory = { "in-progress": new Date() };
        data.date = new Date();
        const docRef = this.firestore.collection(`/ChallengeUsers/${data.uid}/Exhibitions/${data.uid}/Answers/`).doc(data.idRes);
        
        const docSnapshot = await docRef.get();
        if (docSnapshot['exists']) {
            console.warn('Document already exists');
            return 0; // O maneja la situación como desees
        } else {
            await docRef.set(data);
            return data.idRes;
        }
    } else {
        return 0;
    }
}



  async getExhibitions() {
    const claims = await this.storageServices.get("gnx-claims");
    return this.firestore.collection(`/ChallengeUsers/${claims.uid}/Exhibitions/${claims.uid}/Answers/`, ref => 
      ref.where('uid', '==', claims.uid)
         .orderBy('dateString', 'desc')
    )
    .snapshotChanges()
    .pipe(map(actions => 
      actions.map(a => {
        const data = a.payload.doc.data() as ItemData;
        const idRef = a.payload.doc.id;
        return { id: idRef, data };
      })
    ));
  }

  async getDetail(id: string) {
    const claims = await this.storageServices.get("gnx-claims");
    const doc = await this.firestore
      .collection(`/ChallengeUsers/${claims.uid}/Exhibitions/${claims.uid}/Answers/`)
      .doc(id)
      .get().toPromise();

    return doc.exists ? doc.data() : null;
  }

  getStoreName(search: string, filter?: string): Promise<any> {
    const requestOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get(`${environment.gluky.algoliaSearchUrl}/exh_stores?search=${search}`, requestOptions).toPromise();
  }

  async getfieldData(): Promise<any> {
    const token = await this.storageServices.get("gnx-token");
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
    return this.http.get(`${environment.gluky.exhibitionData}/form`, { headers }).toPromise();
  }

  async getfieldTableCollection(): Promise<any> {
    const token = await this.storageServices.get("gnx-token");
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
    return this.http.get(`${environment.gluky.exhibitionData}/tables`, { headers }).toPromise();
  }

  async getfieldTableDataCollection(id: string): Promise<any> {
    const token = await this.storageServices.get("gnx-token");
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
    return this.http.get(`${environment.gluky.exhibitionData}/tables/${id}`, { headers }).toPromise();
  }

async getDataValue(): Promise<void> {
  const fieldsData = await this.storageServices.get("gnx-fieldsData");
  this.alertService.presentAlert("Actualizando nuevos datos de exhibiciones");

  const tables: any = await this.getfieldTableCollection();
  for (const element of tables.data) {
    const valeData = JSON.parse(await this.storageServices.get(`gnx_${element.id}`));
    const tableValue: any = await this.getfieldTableDataCollection(element.id);

    // Comprobación adicional para evitar pasar datos indefinidos
    if (tableValue && tableValue.columns && Array.isArray(tableValue.data)) {
      const dataValues = {
        table: tableValue.id,
        columns: tableValue.columns,
        value: this.orderValueData(tableValue.id, tableValue.columns, tableValue.data),
        updated_at: tableValue.updated_at
      };
      await this.storageServices.set(`gnx_${element.id}`, JSON.stringify(dataValues));
    } else {
      console.error(`tableValue or its properties are undefined or not an array for element ${element.id}`, tableValue);
    }
  }
}


  orderValueData(name: string, columns: any[], data: any[]): any[] {
    if (!data || !Array.isArray(data)) {
      console.error('Data is undefined or not an array:', data);
      return [];
    }
  
    return data.slice(0, Math.min(data.length, 14681)).map(element => {
      if (name === "username" || name === "store_name") {
        return { numero: element[0], cliente: element[1], tipo: element[2] };
      } else {
        return { numero: element[0], tipo: element[1], name: element[2] };
      }
    });
  }
  

  async changeToBase64(fileName: string): Promise<string> {

    const readFile = await Filesystem.readFile({ path: fileName, directory: FilesystemDirectory.Documents });

    return `data:image/png;base64,${readFile.data}`;
  }


  async saveFile(file:any){ 
    // Generar la fecha actual en formato YYYY-MM-DD
    const fechaActual = new Date().toISOString().replace(/[-T:\.Z]/g, '');
    // Crear el nombre de la imagen con el formato deseado
    const fileName = `IMG-unilever-${fechaActual}.png`;

    const dataPhoto=
     await Filesystem.writeFile({
       path: fileName,
       data: file,
       directory: FilesystemDirectory.Documents,
       recursive: true,
     });

   return {'uri':fileName,'base64':file}

}

  async deleteImg(fileName: string): Promise<void> {
    await Filesystem.deleteFile({ path: fileName, directory: FilesystemDirectory.Documents });
  }

  async deleteAllImg(): Promise<void> {
    const directoryName = "DOCUMENTS";
    
    try {
      // Lista los archivos en el directorio para eliminarlos
      const result = await Filesystem.readdir({
        path: directoryName,
        directory: FilesystemDirectory.Documents,
      });

      // Eliminar cada archivo en el directorio
      await Promise.all(result.files.map(file => 
        Filesystem.deleteFile({ path: `${directoryName}/${file}`, directory: FilesystemDirectory.Documents })
      ));

      // Finalmente, eliminar el directorio
      await Filesystem.rmdir({
        path: directoryName,
        directory: FilesystemDirectory.Documents,
        recursive: true, // Cambiar a true si hay subdirectorios
      });

      console.log(`Directory ${directoryName} deleted successfully`);
    } catch (error) {
      console.error(`Error deleting directory ${directoryName}:`, error);
    }
  }

  async uploadExhibitionPhotos(photos: any[], exhibition: any): Promise<void> {
   
    const uploadedPhotos = await Promise.all(photos.map(async (photo) => {
      const photoFileName = uuidv4();
      const ext = 'png';
      const filePath = `users/${exhibition.uid}/exhibition/${photoFileName}.${ext}`;
      const fileRef = this.storage.ref(filePath);

      const task = fileRef.putString(photo.value, 'data_url', { contentType: 'image/png' });
      await task.snapshotChanges().toPromise();
      const downloadURL = await fileRef.getDownloadURL().toPromise();

      return {
        ...photo,
        value: downloadURL // Actualizamos el valor con la URL descargada
      };
    }));

    // Luego actualizamos la exhibición con las URLs de las fotos subidas
    exhibition.photos = uploadedPhotos;
    await this.saveExhibitionFinally(exhibition);
  }

  private async saveExhibitionFinally(exhibition: any): Promise<void> {
    try {
   
      await this.saveExhibition(exhibition);
      await this.storageServices.remove("gnx_localData");
      this.events.publish("exhibition:created");
      this.alertService.presentAlert('Ejecuciones actualizadas');
    } catch (error) {
      console.error('Error saving exhibition', error);
    }
  }
}


