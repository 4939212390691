import { Component, Input, OnInit,OnDestroy } from '@angular/core';
import CameraPhoto, { FACING_MODES } from 'jslib-html5-camera-photo';
import { ModalController, Platform } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { FilesystemDirectory, Plugins, CameraResultType, Capacitor } from '@capacitor/core';
const { Filesystem,Camera } = Plugins;


@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
})
export class CameraComponent implements OnInit, OnDestroy {
  //@ViewChild('slides') slides: slide
  hideVideo = true
  slidesOptions = {
    initialSlide: 0,
    direction: 'horizontal',
    speed: 300,
    spaceBetween: 8,
    slidesPerView: 2.8,
    freeMode: true,
    loop: false
  };;
  element!: HTMLElement;
  @Input() inputProps: {
    name: string;
    onChange: (value:any) => void;
    // onBlur: () => void;
    // onFocus: () => void;
  };
  @Input() onClose: (photos:any) => void;
  @Input() limitPhotos = 1; 
  cameraStarted: boolean = true;
  switchMode: boolean = false;
  cameraPhoto: CameraPhoto;
  videoElement!: HTMLVideoElement;
  showPreview = false;
  srcPreview="";
  previewImages = [];

  constructor(
    public modalController:ModalController,
    public platform: Platform,
    private readonly sanitizer: DomSanitizer
   ) {}

  ngOnInit() {
    this.previewImages = [];
    (el: HTMLVideoElement) => (this.videoElement = el);
    //this.inputProps.onFocus();
    let videoCamera = document.getElementById('videoCamera') as HTMLVideoElement;
    this.cameraPhoto = new CameraPhoto(videoCamera);
    this.startCamera();
  }

  get isIos(){
    return this.platform.is("ios");
  }

  async startCamera(idealFacingMode: typeof FACING_MODES.USER | typeof FACING_MODES.ENVIRONMENT = FACING_MODES.ENVIRONMENT) {
    this.cameraPhoto
      .startCamera(idealFacingMode, { width: 640, height: 480 })
      .then(() => {
        this.cameraStarted = true;
        this.hideVideo = false
        console.log('Camera started !', this.cameraPhoto);
        console.log('this.cameraStarted !', this.cameraStarted);
      })
      .catch((error:any) => {
        this.cameraStarted = false;
        this.hideVideo = true
        console.error('Camera not started!', error);
      });
  }



  async changeMode() {
    this.switchMode = !this.switchMode;
    const facingMode = this.switchMode ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT;
    this.startCamera(facingMode);
  }

  async takePhoto() {
    //this.previewImages.push(this.srcPreview);
    http://localhost/_capacitor_file_/path/to/device/file://DOCUMENTS/vctgo/IMG/IMG-vct-20240508013419205.jpeg
 
    if(this.srcPreview){
      var data = this.savePhoto();
     let url = (await data)
     console.log("***url",url);
     this.previewImages.push(url);
   
    
    }
  
    this.showPreview = true;
    this.srcPreview = "";
    this.startCamera();
  }
  async savePhoto(){ 
       // Generar la fecha actual en formato YYYY-MM-DD
       const fechaActual = new Date().toISOString().replace(/[-T:\.Z]/g, '');
       // Crear el nombre de la imagen con el formato deseado
       const fileName = `IMG-unilever-${fechaActual}.png`;

       const dataPhoto=
        await Filesystem.writeFile({
          path: fileName,
          data: this.srcPreview,
          directory: FilesystemDirectory.Documents,
          recursive: true,
        });

      return {'uri':fileName,'base64':this.srcPreview}
  
  }


  async validatePhoto() {
    const dataUri = this.cameraPhoto.getDataUri({});
    this.showPreview = true;
    this.srcPreview = dataUri;
    this.cameraStarted = false;
  }

  async cancelPhoto() {
    this.showPreview = false;
    this.srcPreview = "";
    this.startCamera();
  }

  async sendPhotos() {
    console.log("senPhoto",this.previewImages)
    //this.inputProps.onChange(this.previewImages);

    this.dismissModal();
  }

  deleteImage(index:any){
    this.previewImages.splice(index, 1)
  }

  async dismissModal() {
    if (this.cameraPhoto.stream) {
      this.modalController.dismiss()
      await this.cameraPhoto.stopCamera();
    }
    //this.inputProps.onBlur();
    this.onClose(this.previewImages);
  }

  ngOnDestroy() {
    this.previewImages=[]
    console.log("chao aqui camara");
    this.dismissModal()
    
  }
}
