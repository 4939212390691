import {
  NgModule,
  isDevMode,
  LOCALE_ID,
  APP_INITIALIZER,
  ErrorHandler,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
import { DatePipe, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';

import { NgxSpinnerModule } from 'ngx-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  AngularFireRemoteConfigModule,
  SETTINGS,
} from '@angular/fire/remote-config';
import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';

import { environment } from 'src/environments/environment';
import { ServerHttpInterceptor } from '../app/services/interceptor/server-http.interceptor';
import { IonBottomSheetModule } from 'ion-bottom-sheet';
import { ComponentsModule } from './components/components.module';
import { KpiModule } from './components/kpi/kpi.module';
import { ImageThanksPipe } from './pipes/images-thanks.pipe';
import { ImgIconsPipe } from './pipes/img-icons.pipe';
import { ImagesThanksKeyPipe } from "./pipes/imges-thanks-key.pipe";
import { setupConfig, GlukyConfigType } from 'gnx-social-network-wcomponent';
import { setupConfig as challengeSetup } from 'gnx-challenges-wcomponent';
import { TagsService } from './services/tags/tags.service';
import { NgxCaptchaModule } from 'ngx-captcha';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es');
import { LivechatWidgetModule } from '@livechat/angular-widget';

/** init social config */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { MapPageRoutingModule } from './pages/private/map/map-routing.module';
import { AgmCoreModule } from '@agm/core';
import { InvoiceModule } from './components/invoice/invoice.module';
import {
  CircleProgressOptions,
  NgCircleProgressModule,
} from 'ng-circle-progress';
import { BuildDetailsHttpService } from './services/build-detail/build-details.http.service';
import { GlobalErrorHandlerService } from './services/globalErrorHandler/global-error-handler.service';

import { CloudinaryModule, CloudinaryConfiguration } from '@cloudinary/angular-5.x';
import { Cloudinary } from 'cloudinary-core';
import { IonicSelectableModule } from 'ionic-selectable';
import { Network } from '@ionic-native/network/ngx';


firebase.initializeApp(environment.firebaseConfig);

const config: GlukyConfigType = {
  firebaseApp: firebase,
  prefixCollections: 'walltest', // prefix name for firestore collections
  programMode: 'program',
  algoliaConfig: {
    apiKey: '724c770c4ee9b934794f2928544d891e',
    appId: '30Q9KEWDD0',
    usersIndexName: 'dev_users',
    hashtagsIndexName: 'dev_hashtags',
  },
  debug: true,
  pixoEditorConfig: {
    apiKey: 'l5yf4gr2wn40',
    theme: 'wordpress',
    height: '95%',
    width: '95%',
    overlay: 'rgba(209, 209, 209)',
    logosrc: 'none',
    features: [
      'adjustments',
      'filters',
      //"draw",
      'crop',
      //"stickers",
      'text',
      'transform',
      //"frame",
      //"blur",
      //"shape",
    ],
    stickers: [
      {
        // This is the PNG image that will be inserted. The larger size the better.
        // Required. The URL must be publicly accessible or CORS-friendly.
        src: 'https://i.pravatar.cc/150?img=3',

        // This is the thumbnail image that will be shown in the property panel.
        // Optional. If missing "src" will be used. The URL may not be CORS-friendly
        // or publicly accessible.
        thumb: 'https://i.pravatar.cc/100?img=3',

        // Sticker caption that will be printed in the property panel below the image.
        // Required.
        caption: 'My Cool Sticker1',

        // Keywords are not displayed, but are considered when filtering stickers.
        // Optional.
        keywords: ['cool', 'awesome'],
      },
      {
        src: 'https://i.pravatar.cc/150?img=4',
        thumb: 'https://i.pravatar.cc/100?img=4',
        caption: 'My Cool Sticker2',
        keywords: ['cool2', 'awesome2'],
      },
    ],
  },
  publicInfoConfig: {
    dateBorn: true,
    docNumber: true,
    docType: true,
    email: true,
    genre: true,
    lastnames: true,
    name: true,
    phone: true,
    uid: true,
    photoURL: true,
  },
  colorGlukyConfig: "#ef0077",
};

setupConfig(config);
/** end social config */

challengeSetup({
  firebaseApp: firebase,
  prefixCollections: '', // prefix name for firestore collections
});

export function fetchBuildDetails(
  buildDetailsService: BuildDetailsHttpService
) {
  return () => buildDetailsService.fetchBuildDetails();
}
/** end challenges config */

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    IonicSelectableModule,
    LivechatWidgetModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      swipeBackEnabled: false,
    }),
    AppRoutingModule,
    ComponentsModule,
    KpiModule,
    InvoiceModule,
    HttpClientModule,
    NgxSpinnerModule,
    ServiceWorkerModule.register('/combined-sw.js', { enabled: true }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireRemoteConfigModule,
    IonBottomSheetModule,
    MapPageRoutingModule,
    NgxCaptchaModule,
    NgCircleProgressModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gmApiKey,
      libraries: ['geometry', 'places'],
    }),
    ServiceWorkerModule.register('/service-worker.js', { enabled: true }),
    CloudinaryModule.forRoot({Cloudinary}, { ...environment.cloudinary_config } as CloudinaryConfiguration),
  ],
  providers: [
    Network,
    {
      provide: APP_INITIALIZER,
      useFactory: fetchBuildDetails,
      deps: [BuildDetailsHttpService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'es' },
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerHttpInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: SETTINGS,
      useFactory: () => (isDevMode() ? { minimumFetchIntervalMillis: 0 } : {}),
    },
    AppVersion,
    Market,
    FirebaseDynamicLinks,
    GooglePlus,
    TagsService,
    DatePipe,
    SmsRetriever,
    CircleProgressOptions,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
