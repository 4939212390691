import { Injectable } from "@angular/core";
import {
  ToastController,
  LoadingController,
  AlertController,
  ModalController,
} from "@ionic/angular";
import { Market } from "@ionic-native/market/ngx";
import { LanguagesService } from "../languagesService/languages.service";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  actualAlert: any;
  isLoading = false;
  modalWeb: HTMLIonModalElement;
  constructor(
    public toastController: ToastController,
    public alertController: AlertController,
    private market: Market,
    public loadingCtrl: LoadingController,
    private languageService: LanguagesService,
    public modalController: ModalController,
    private router: Router
  ) {}

  async presentAlert(message) {
    this.actualAlert = await this.toastController.create({
      message,
      position: "top",
      duration: 3000,
    });
    this.actualAlert.present();
  }

  async closeAlert() {
    if(this.actualAlert){
      this.actualAlert.dismiss();
    }
  }

  async presentAlertWithMessage(message: any, options: any) {
    this.actualAlert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: message.header,
      message: message.description,
      buttons: [...options],
    });
    await this.actualAlert.present();
  }
  async presentAlertUpdateApp(version: any) {
    const alert = await this.alertController.create({
      header: `${this.getTextIUNewUpdate.title}`,
      message: `<strong>${this.getTextIUNewUpdate.description}${environment.appName}</strong>`,
      backdropDismiss: false,
      buttons: [
        {
          text: `${this.getTextIUNewUpdate.button_name}`,
          handler: () => {
            this.market.open(environment.dinamycLink.packageName);
          },
        },
      ],
    });

    await alert.present();
  }
  async show(msg?) {
    this.isLoading = true;
    return await this.loadingCtrl
      .create({
        message: msg,
        spinner: "bubbles",
      })
      .then((a) => {
        a.present().then(() => {
          //console.log('presented');
          if (!this.isLoading) {
            a.dismiss().then(() => console.log("abort presenting"));
          }
        });
      });
  }

  // @TODO Servicio para que cuando cierre un modal abra otro
  openSecondPopup(modal, component) {
    modal.onDidDismiss().then(() => {
      this.openPopup(component, "gnx-modal-radius");
    });
  }

  // @TODO Servicio para abrir popups
  async openPopup(component, cssClass, data?, componentToOpen?) {
    // @TODO web por ahora se puso esta validacion porque cuando abre el pop up del checkout se abre en el login tambien
    // pendiente por corregir
    if (this.router.url === "/login") {
      return;
    }
    const modalsOpen = await this.modalController.getTop();
    if (modalsOpen) {
      await modalsOpen.dismiss();
    }
    this.modalWeb = await this.modalController.create({
      component: component,
      swipeToClose: true,
      cssClass: cssClass,
      componentProps: data,
    });
    if (componentToOpen) {
      this.openSecondPopup(this.modalWeb, componentToOpen);
    }
    return await this.modalWeb.present();
  }

  dismissPopup() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  async hide() {
    this.isLoading = false;
    return await this.loadingCtrl
      .dismiss()
      .then(() => console.log("dismissed"));
  }

  async showErrorAlert(msg) {
    const alert = await this.alertController.create({
      header: "Error",
      message: msg,
      buttons: ["OK"],
    });
    await alert.present();
  }

  async presentOrderStatus(message, icon, cssClass) {
    const isAlertOpened = await this.alertController.getTop();
    if (isAlertOpened) {
      await this.alertController.dismiss();
    }
    this.actualAlert = await this.alertController.create({
      mode: "ios",
      backdropDismiss: false,
      message: `
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-icon class=${cssClass} name=${icon}></ion-icon>
        </ion-row
        <ion-row class="ion-justify-content-center">
          <ion-text>
              <p>${message}</p>
            </ion-text>
        </ion-row>
      </ion-grid>
`,
    });
    return await this.actualAlert.present();
  }
  get getTextIUNewUpdate() {
    return this.languageService.textsIU.new_update;
  }
  async showAlert(msg) {
    const alert = await this.alertController.create({
      header: "Atención",
      message: msg,
      buttons: [
        {
          text: 'Entendido.',
          role: 'confirm',
          handler: () => { window.location.reload(); }
        }
      ]
    });
    await alert.present();
  }


  async presentAlertMiddle(message) {
    this.actualAlert = await this.toastController.create({
      message,
      position: "middle",
      duration: 3500,
    });
    this.actualAlert.present();
  }
  
}
