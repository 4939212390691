import { TagsService } from './services/tags/tags.service';
import { PwaService } from './services/pwa/pwa.service';
import { Component, HostListener, OnInit } from '@angular/core';
import {
  Platform,
  AlertController,
  ToastController
} from '@ionic/angular';

import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';

//Services
import { RemoteConfigService } from './services/remote-config/remote-config.service';

import { ValidateVersionService } from './services/validateVersion/validate-version.service';
import { RouterService } from './services/router/router.service';
import { AuthService } from './services/auth/auth.service';
import { LanguagesService } from './services/languagesService/languages.service';
import { FcmService } from './services/fcm/fcm.service';
import { AuthGnxService } from 'src/app/services/authGnx/auth-gnx.service';
import { UpToDateBuildService } from './services/build-detail/up-to-date.service';
import { getTagsConfigClicked } from './services/tags/tagsConfig';
import { SqliteService } from 'src/app/services/sqlite/sqlite.service';
import { ExhibitionsService } from 'src/app/services/exhibitions/exhibitions.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { StorageService } from './services/storage/storage.service';
import { Capacitor } from '@capacitor/core';
import { ConnectivityService } from 'src/app/services/connectivity/connectivity.service';
import { environment } from 'src/environments/environment';
import { EmblueService } from './services/emblue/emblue.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  settingsDidMount: boolean = false;
  public isWeb: boolean;
  public load: boolean;
  onlineMode: boolean;
  public alertButtons = [
    {
      text: 'Actualizar',
      role: 'confirm',
      handler: () => {
        // this.StorageService.removeAll();
        window.location.reload();
      },
    },
  ];


  @HostListener('click', ['$event.target']) onClick(e:HTMLElement) {
    const tagEvent:any = e.getAttribute('tag_event_key');
    // console.debug(e)
    if (!tagEvent) return;
    this.tagsService.setTagFromEvent(tagEvent);
  }

  constructor(
    private platform: Platform,
    private remoteConfigService: RemoteConfigService,
    private validateVersion: ValidateVersionService,
    private firebaseDynamicLinks: FirebaseDynamicLinks,
    private routerService: RouterService,
    private authService: AuthService,
    private languageService: LanguagesService,
    private fcmService: FcmService,
    private authGnx: AuthGnxService,
    private PwaService: PwaService,
    private upToDateService: UpToDateBuildService,
    private tagsService: TagsService,
    public  alertController: AlertController,
    private exhibitionsService:ExhibitionsService,
    private loaderService: LoaderService,
    private sqliteService: SqliteService,
    private storageService: StorageService,
    private toastController: ToastController,
    private networkService: ConnectivityService,
    private apiService: EmblueService
  ) {
    this.initializeApp();
    this.authService.setApiKeyByPlatform();

    const updateApp$ = this.upToDateService.buildIsUpToDate.subscribe(
      async (buildIsUpToDate) => {
        if (!buildIsUpToDate) return;

        const alert = await this.alertController.create({
          header: 'Actualizacion',
          subHeader: 'La aplicacion tiene una actualizacion importante',
          buttons: this.alertButtons,
          backdropDismiss: false,
        });
        await alert.present();
        updateApp$.unsubscribe();
      }
    );
  }

  ngOnInit() {
    if(environment.production){
      console.log = function(){} ;
      console.error = function(){};
      console.warn = function(){};
    }
    const language = navigator.languages.includes('-');
    if (!this.languageService.loadedLanguage) {
      this.languageService.loadLanguage('en');
    }
  
    this.PwaService.initPwaPrompt();

    this.networkService.getNetworkStatus().subscribe((connected: boolean) => {
      if(connected){
        // this.initSqlite();
    }})
  
  }
  async initializeApp() {
    // this.apiService.getNotImplemented().subscribe(
    //   response => {
    //     console.log('API response:', response);
    //   }
    //   error => {
    //     console.error('API error:', error);
    //   }
    // );
  
    // this.authService.getParamsAuth("https://gnx-mvp.firebaseapp.com/auth-confirmation?apiKey=AIzaSyCzc4umKlI4KtSpgl41U0axBh40Mf-QBYM&mode=verifyEmail&oobCode=1A008dXFVM84vesFyaqwbNpr0A_9Zq1DBs9a1uyRCJAAAAF2gh9Vsw&continueUrl=https://gnx-mvp.firebaseapp.com/&lang=es-419")
    this.validateVersion.validate();
    //  await this.settignsService.initSettings();
    this.listenForDynamicLinks();

    this.platform.ready().then(() => {
      //this.analyticsService.initAnalytics();
      this.fcmService.initPush();
    });

    this.settingsDidMount = true;
    this.remoteConfigService.initRemoteConfig();

    if (window.location.pathname != '/login') {
      this.authGnx
        .confirmSignIn(window.location.href)
        .then(async (auth) => {
          if (auth) {
            console.log(
              'entro al avalidacion del home',
              auth,
              auth.user.tokenReresh
            );
            await this.authGnx.loginUserWithLink();
            //this.navCtrl.navigateRoot("app/home");
          }
        })
        .catch((err) => {
          console.error('error app XXXXX', err);
        });
    }
  }

  listenForDynamicLinks() {
    this.firebaseDynamicLinks.onDynamicLink().subscribe(
      (res: any) => {
        this.authService.getParamsAuth(res.deepLink);
        this.routerService.redirect('auth-confirmation');
        //  window.location.href = res.deepLink;k
      },
      (error: any) => console.log(error)
    );
  }

  /**
   * Deshabilita el menu de notificaciones teniendo en cuenta la rutas
   * @return { Boolean}
   * @memberof AppComponent
   */
  getPathDisableMenuNotifications() {
    const paths = [
      'login',
      'register',
      'recover-account',
      'live-chat',
      'register-info-extra',
    ]; // para inhabilitar el menu en una ruta solo añada la ruta en este array
    let disable = false;
    paths.forEach((data) => {
      if ((this.routerService.getCurrentUrl() as string)?.includes(data)) {
        disable = true;
      }
    });
    return disable;
  }
  getPathDisableNotiForExh() {
    const paths = [
      'login',
      'register',
      'recover-account',
      'register-info-extra',
      'exhibitions',
      'exhibitions/form',
      'exhibition-success'
    ]; // para inhabilitar el menu en una ruta solo añada la ruta en este array
    let disable = false;
    paths.forEach((data) => {
      if ((this.routerService.getCurrentUrl() as string)?.includes(data)) {
        disable = true;
      }
    });
    return disable;
  }

  // async initSqlite(){
  //   //this.sqliteService.init();
  //   let exhiData= await this.getFieldData();
  //   console.log("200",exhiData[0]);
  //   let dateExhbi= exhiData[0]['update_at']['_seconds']
  //   this.storageService.get("gnx-fieldsData-update_at").then(date =>{
  //     if( !date){
  //       console.log("no tiene")
  //       this.sqliteService.init();
  //       this.sqliteService.dbReady.subscribe(load => {
  //         console.log("no tiene",load)
  //         this.load = load;
  //         if(this.load){
  //           for (let index = 0; index < exhiData[0]['fields'].length; index++) {
  //             const element = exhiData[0]['fields'][index];
  //             this.sqliteService.create(element).then( (changes) =>{
  //               console.log(changes);
  //             }).catch(err => {
  //               console.error(err);
  //               console.error("Error al crear");
  //             })
  //           }
  //           this.read()
  //         }
  //       })
  //     }else if(date || date !== dateExhbi){
  //       console.log("no son iguales")
  //     }
  //   })
  // }

    // read(){
  //   // Leemos los datos de la base de datos
  //   this.sqliteService.read().then( (datas: string[]) => {
      
  //     console.log("Leido",datas);
      
  //   }).catch(err => {
  //     console.error(err);
  //     console.error("Error al leer");
  //   })
  // }

  async getFieldData(){
    let fields=[];
    await this.exhibitionsService.getfieldData().then(async fieldsData =>{
      console.log(fieldsData)
       fields.push(fieldsData)
      //this.storageService.set("gnx-fieldsData-update_at",fieldsData['update_at']['_seconds']);
    })
    return fields
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: '!Actualizando datos para las exhibiciones!',
      duration: 1500,
      position: "top",
    });

    await toast.present();
  }

  // read(){
  //   // Leemos los datos de la base de datos
  //   this.sqliteService.read().then( (datas: string[]) => {
      
  //     console.log("Leido",datas);
      
  //   }).catch(err => {
  //     console.error(err);
  //     console.error("Error al leer");
  //   })
  // }
}
